import React, { memo, useCallback } from 'react';

import { useGlobals } from '@storybook/manager-api';
import { Icons, IconButton } from '@storybook/components';
import { TOOL_ID } from './constants';

export const Tool = memo(() => {
  const [{ darkMode, className }, updateGlobals] = useGlobals();

  const toggleMyTool = useCallback(() => {
    updateGlobals({
      darkMode: !darkMode,
      className,
    });
  }, [darkMode]);

  return (
    <IconButton key={TOOL_ID} active={darkMode} title="Dark mode" onClick={toggleMyTool}>
      <Icons icon="moon" />
    </IconButton>
  );
});
